import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AdminModule from "@/store/module/admin/AdminModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import HandbookModule from "@/store/module/shared/HandbookModule";
let UserUpdateRequestView = class UserUpdateRequestView extends Vue {
    constructor() {
        super(...arguments);
        this.updateProfile = {
            dialog: false,
        };
        this.title = {
            main: {
                title: "Основная информация",
                fields: {
                    name: { notSpecified: "Не указано", title: "Имя" },
                    surname: { notSpecified: "Не указана", title: "Фамилия" },
                    patronymic: { notSpecified: "Не указано", title: "Отчество" },
                    birthdate: { notSpecified: "Не указан", title: "День рождения" },
                    gender: { notSpecified: "Не указан", title: "Пол" },
                    phone: { notSpecified: "Не указан", title: "Телефон" },
                    region: { notSpecified: "Не указан", title: "Регион" },
                    city: { notSpecified: "Не указан", title: "Город" },
                    inNationalTeamSinceYear: { notSpecified: "Не указано", title: "Год, с которого состоит в сборной" },
                    sportsCategoryId: { notSpecified: "Не указан", title: "Спортивное звание/разряд" },
                },
            },
            passport: {
                title: "Паспортные данные",
                fields: {
                    serial: {
                        notSpecified: "Не указана",
                        title: "Серия",
                    },
                    number: {
                        notSpecified: "Не указан",
                        title: "Номер",
                    },
                    authorityCode: {
                        notSpecified: "Не указан",
                        title: "Код подразделения",
                    },
                    issueDate: {
                        notSpecified: "Не указано",
                        title: "Дата выдачи",
                    },
                    authority: {
                        notSpecified: "Не указано",
                        title: "Кем выдан",
                    },
                    birthPlace: {
                        notSpecified: "Не указано",
                        title: "Место рождения",
                    },
                },
            },
            equip: {
                title: "Экипировка",
                fields: {
                    size: {
                        notSpecified: "Не указано",
                        title: "Размер одежды",
                    },
                    height: {
                        notSpecified: "Не указан",
                        title: "Рост",
                    },
                    shoeSize: {
                        notSpecified: "Не указан",
                        title: "Размер обуви",
                    },
                },
            },
            employment: {
                title: "Образование / Занятость",
                fields: {
                    education: {
                        notSpecified: "Не указано",
                        title: "Образование",
                    },
                    educationPlace: {
                        notSpecified: "Не указано",
                        title: "Место учебы",
                    },
                    workPlace: {
                        notSpecified: "Не указано",
                        title: "Место работы",
                    },
                },
            },
            living: {
                title: "Место проживания",
                fields: {
                    address: {
                        notSpecified: "Не указан",
                        title: "Адрес",
                    },
                    index: {
                        notSpecified: "Не указан",
                        title: "Индекс",
                    },
                },
            },
            social: {
                title: "Социальные службы / МЕДСТРАХ",
                fields: {
                    medicialInsurance: {
                        notSpecified: "Не указан",
                        title: "Номер страхового полиса ОМС",
                    },
                    TIN: {
                        notSpecified: "Не указан",
                        title: "ИНН",
                    },
                    INIPA: {
                        notSpecified: "Не указан",
                        title: "СНИЛС",
                    },
                },
            },
            aboard: {
                title: "Выезды за рубеж",
                fields: {
                    hasAboardPassport: {
                        title: "Есть заграничный пасспорт",
                        notSpecified: "Нет",
                    },
                    surname: {
                        notSpecified: "Не указана",
                        title: "Фамилия",
                    },
                    name: {
                        notSpecified: "Не указано",
                        title: "Имя",
                    },
                    patronymic: {
                        notSpecified: "Не указано",
                        title: "Отчество",
                    },
                    number: {
                        notSpecified: "Не указан",
                        title: "Номер",
                    },
                    authority: {
                        notSpecified: "Не указано",
                        title: "Кем выдан",
                    },
                    issueDate: {
                        notSpecified: "Не указана",
                        title: "Дата выдачи",
                    },
                    expirationDate: {
                        notSpecified: "Не указана",
                        title: "Дата истечения срока",
                    },
                    visitedCountries: {
                        notSpecified: "Не указаны",
                        title: "Посещенные страны",
                    },
                    hasUsaVisa: {
                        notSpecified: "Нет",
                        title: "Есть виза в США",
                    },
                    hasBiometrics: {
                        notSpecified: "Нет",
                        title: "Сдавалась биометрия",
                    },
                    biometricsIssueDate: {
                        notSpecified: "Не указано",
                        title: "Дата сдачи биометрии",
                    },
                },
            },
            parents: {
                title: "Родители",
                fields: {
                    motherSNP: {
                        notSpecified: "Не указано",
                        title: "Ф.И.О матери",
                    },
                    motherBirthdate: {
                        notSpecified: "Не указано",
                        title: "День рождения матери",
                    },
                    fatherSNP: {
                        notSpecified: "Не указано",
                        title: "Ф.И.О отца",
                    },
                    fatherBirthdate: {
                        notSpecified: "Не указано",
                        title: "День рождения отца",
                    },
                },
            },
            familyStatus: {
                title: "Семейное положение",
                fields: {
                    partnershipType: {
                        notSpecified: "Не указано",
                        title: "Семейное положение",
                    },
                    partnerSNP: {
                        notSpecified: "Не указано",
                        title: "Ф.И.О супруга/супруги",
                    },
                    partnerMaidenName: {
                        notSpecified: "Не указана",
                        title: "Девичья фамилия супруги",
                    },
                    partnerCitizenship: {
                        notSpecified: "Не указано",
                        title: "Гражданство супруга/супруги",
                    },
                    partnerBirthdate: {
                        notSpecified: "Не указано",
                        title: "День рождения супруга/супруги",
                    },
                },
            },
            sportsman: {
                title: "Данные спортсмена",
                fields: {
                    disciplineGroup: {
                        notSpecified: "Не указана",
                        title: "Группа дисциплин",
                    },
                    discipline: {
                        notSpecified: "Не указана",
                        title: "Дисциплина",
                    },
                    startYear: {
                        notSpecified: "Не указан",
                        title: "Год начала занятий",
                    },
                    firstCoachSNP: {
                        notSpecified: "Не указано",
                        title: "Ф.И.О первого тренера",
                    },
                    personalCoachSNP: {
                        notSpecified: "Не указано",
                        title: "Ф.И.О личного тренера",
                    },
                },
            },
            coach: {
                title: "Данные тренера",
                fields: {
                    category: {
                        notSpecified: "Не указана",
                        title: "Тренерская категория (звание)",
                    },
                },
            },
            judge: {
                title: "Данные судьи",
                fields: {
                    category: {
                        notSpecified: "Не указана",
                        title: "Категория спортивного судьи",
                    },
                    order: {
                        notSpecified: "Не указан",
                        title: "№ приказа",
                    },
                    orderDate: {
                        notSpecified: "Не указана",
                        title: "Дата приказа",
                    },
                },
            },
        };
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get avatar() {
        return this.request && this.request.main && this.request.main.avatar
            ? `${IMAGE_SERVER_URL}/public/${this.request.main.avatar}`
            : null;
    }
    get regions() {
        return this.$store.getters[HandbookModule.types.getters.REGIONS];
    }
    get cities() {
        return this.$store.getters[HandbookModule.types.getters.CITIES];
    }
    get sportsCategories() {
        return this.$store.getters[HandbookModule.types.getters.SPORTS_CATEGORY];
    }
    get view() {
        if (!this.request)
            return [];
        let data = [];
        if (this.request.main) {
            const view = {
                key: this.title.main.title,
                title: this.title.main.title,
                items: [],
            };
            for (const key in this.request.main) {
                const mainKey = key;
                if (mainKey === "avatar")
                    continue;
                if (mainKey === "address") {
                    const region = this.request.main.address.regionId
                        ? this.regions.find((r) => r.id === this.request.main.address.regionId).name
                        : this.title.main.fields.region.notSpecified;
                    const city = this.request.main.address.cityId
                        ? this.cities.find((c) => c.id === this.request.main.address.cityId).name
                        : this.title.main.fields.city.notSpecified;
                    view.items.push({
                        title: this.title.main.fields.region.title,
                        value: region,
                    }, {
                        title: this.title.main.fields.city.title,
                        value: city,
                    });
                }
                else if (mainKey === "sportsCategoryId") {
                    view.items.push({
                        title: this.title.main.fields[mainKey].title,
                        value: this.sportsCategories.find((c) => c.id === this.request.main.sportsCategoryId)?.category ||
                            this.title.main.fields[mainKey].notSpecified,
                    });
                }
                else {
                    view.items.push({
                        title: this.title.main.fields[mainKey].title,
                        value: this.request.main[mainKey]
                            ? this.request.main[mainKey]
                            : this.title.main.fields[mainKey].notSpecified,
                    });
                }
            }
            if (view.items.length > 0) {
                data = data.concat(view);
            }
        }
        console.log(this.request);
        if (this.request.sportsman) {
            let counter = 1;
            for (const sportsmanItem of this.request.sportsman) {
                const view = {
                    key: this.title.sportsman.title,
                    title: this.title.sportsman.title + ` №${counter}`,
                    items: [],
                };
                for (const key in sportsmanItem) {
                    const sportsmanKey = key;
                    if (sportsmanKey === "firstCoachId" || sportsmanKey === "personalCoachId")
                        continue;
                    view.items.push({
                        title: this.title.sportsman.fields[sportsmanKey].title,
                        value: sportsmanItem[sportsmanKey]
                            ? sportsmanItem[sportsmanKey]
                            : this.title.sportsman.fields[sportsmanKey].notSpecified,
                    });
                }
                data = data.concat(view);
                counter += 1;
            }
        }
        const copy = Object.assign({}, this.request);
        delete copy.main;
        delete copy.sportsman;
        for (const updateRequestField in copy) {
            const fieldKey = updateRequestField;
            console.log(fieldKey);
            const view = {
                key: this.title[fieldKey].title,
                title: this.title[fieldKey].title,
                items: [],
            };
            for (const key in copy[fieldKey]) {
                const field = this.request[fieldKey];
                let value;
                if (typeof field[key] === "boolean") {
                    value = field[key] ? "Да" : "Нет";
                }
                else {
                    value = field[key] ? field[key] : this.title[fieldKey].fields[key].notSpecified;
                }
                view.items.push({
                    title: this.title[fieldKey].fields[key].title,
                    value,
                });
            }
            data = data.concat(view);
        }
        const dataOrder = Object.keys(this.title).map((key) => this.title[key].title);
        return data.sort((a, b) => dataOrder.indexOf(a.key) - dataOrder.indexOf(b.key));
    }
    mounted() { }
};
__decorate([
    Prop({ type: Object, required: true })
], UserUpdateRequestView.prototype, "request", void 0);
UserUpdateRequestView = __decorate([
    Component({
        components: {},
    })
], UserUpdateRequestView);
export default UserUpdateRequestView;
